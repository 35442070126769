import Box from "@mui/material/Box";
import Chip, { ChipProps } from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { ClientType } from "../../types";
import { ClientStatusEnum, ClientStatusEnumColour, ClientStatusEnumDisplay } from "../../enums/ClientStatus";
import { chain, debounce, includes, toLower } from "lodash";

interface ClientSearchProps {
  clients: ClientType[];
  onSearchResults: (results: ClientType[]) => void;
}

const SearchChip = ({ label, ...rest }: Partial<ChipProps>) => {
  return <Chip size="medium" sx={{ ml: 1.5 }} label={label} variant="outlined" {...rest} />;
};

const ClientSearch = ({ onSearchResults, clients }: ClientSearchProps) => {
  const [statusFilter, setStatusFilter] = useState<ClientStatusEnum>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<ClientType[]>();

  // manage the value of the search field separately from the actual search term to allow for debounce
  const [searchFieldValue, setSearchFieldValue] = useState<string>("");

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSearchFieldValue(event.target.value);
  };

  const doSearch = useCallback((clients: ClientType[], status: ClientStatusEnum | undefined, name: string) => {
    const searchResults = chain(clients)
      .filter((client) => (status ? client.status === status : true))
      .filter((client) => (name ? includes(toLower(client.name), toLower(name)) : true))
      .orderBy((client) => client.name)
      .value();
    setSearchResults(searchResults);
  }, []);

  const handleClear = () => {
    setSearchFieldValue("");
    setSearchTerm("");
  };

  const debouncedSetSearchTerm = useMemo(
    () =>
      debounce((newSearchTerm: string) => {
        setSearchTerm(newSearchTerm);
      }, 150),
    []
  );

  useEffect(() => {
    debouncedSetSearchTerm(searchFieldValue);
  }, [searchFieldValue, debouncedSetSearchTerm]);

  useEffect(() => {
    doSearch(clients, statusFilter, searchTerm);
  }, [searchTerm, statusFilter, clients, doSearch]);

  useEffect(() => {
    if (searchResults) {
      onSearchResults(searchResults);
    }
  }, [searchResults, onSearchResults]);

  return (
    <Box sx={{ display: "flex" }}>
      {/* SEARCH FIELD */}
      <TextField
        sx={{ flexGrow: 1 }}
        value={searchFieldValue}
        autoFocus
        placeholder="Search"
        size="small"
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClear} size="small">
                <CloseIcon sx={{ color: "primary.main" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {/* SEARCH CHIPS */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <SearchChip
          label="All"
          color={statusFilter === undefined ? "primary" : "neutral"}
          onClick={() => setStatusFilter(undefined)}
        />

        {(Object.keys(ClientStatusEnum) as Array<ClientStatusEnum>).map((status) => (
          <SearchChip
            label={ClientStatusEnumDisplay[status]}
            key={status}
            color={statusFilter === status ? ClientStatusEnumColour[status] : "neutral"}
            onClick={() => setStatusFilter(status)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ClientSearch;
