import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { FileType, RequirementType } from "../../types";
import UploadFile from "../UploadFile";
import FileList from "../file/FileList";
import { filter, includes } from "lodash";
import useClientService from "../../hooks/ClientService";
import useHttpFunctions from "../../hooks/HttpFunctions";

type RequirementFilesProps = {
  organisationId: string;
  clientId: string;
  periodId: string;
  entityId: string;
  categoryId: string;
  subCategoryId: string | undefined;
  requirement: RequirementType;
  files: FileType[];
  onFileChange: () => void;
};

const RequirementFiles = ({
  organisationId,
  clientId,
  periodId,
  entityId,
  categoryId,
  subCategoryId,
  requirement,
  files,
  onFileChange,
}: RequirementFilesProps) => {
  const clientService = useClientService();
  const httpFunctions = useHttpFunctions();

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [requirementFiles, setRequirementFiles] = useState<FileType[]>([]);

  useEffect(() => {
    const results = filter(files, (file) => includes(requirement.fileIds, file.fileId));
    setRequirementFiles(results);
  }, [requirement, files]);

  useEffect(() => {
    setIsEditable(requirement.status !== "COMPLETE");
  }, [requirement.status]);

  const removeFile = async (file: FileType) => {
    const { requirementId } = requirement;
    const { fileId } = file;
    await httpFunctions.deleteFile({
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      requirementId,
      fileId,
    });
    onFileChange();
  };

  const renameFile = async (file: FileType, name: string) => {
    const { fileId } = file;
    await clientService.renameFile(organisationId, clientId, periodId, fileId, name);
    onFileChange();
  };

  const togglePermanent = async (file: FileType) => {
    await clientService.togglePermanent(organisationId, clientId, periodId, file.fileId);
    onFileChange();
  };

  return requirement ? (
    <Box sx={{ mt: 3 }}>
      {isEditable ? (
        <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            Please attach any relevant files if required
          </Typography>
          <UploadFile
            organisationId={organisationId}
            clientId={clientId}
            periodId={periodId}
            entityId={entityId}
            categoryId={categoryId}
            subCategoryId={subCategoryId}
            requirementId={requirement.requirementId}
            uploadCallback={onFileChange}
          />
        </Box>
      ) : (
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          Files
        </Typography>
      )}

      <FileList
        files={requirementFiles}
        remove={removeFile}
        rename={renameFile}
        togglePermanent={togglePermanent}
        isEditable={isEditable}
      />
    </Box>
  ) : null;
};

export default RequirementFiles;
