import { useMemo } from "react";

export class PathService {
  public getUsersCollectionPath(): string {
    return "/users";
  }

  public getUserDocumentPath(userId: string): string {
    return `${this.getUsersCollectionPath()}/${userId}`;
  }

  public getInvitesCollectionPath(): string {
    return "/invites";
  }

  public getInviteDocumentPath(inviteId: string): string {
    return `${this.getInvitesCollectionPath()}/${inviteId}`;
  }

  public getToursCollectionPath(): string {
    return "/tours";
  }

  public getTourDocumentPath(tourId: string): string {
    return `${this.getToursCollectionPath()}/${tourId}`;
  }

  public getTourUsersCollectionPath(tourId: string): string {
    return `${this.getTourDocumentPath(tourId)}/users`;
  }

  public getTourUserDocumentPath(tourId: string, userId: string): string {
    return `${this.getTourUsersCollectionPath(tourId)}/${userId}`;
  }

  public getOrganisationsCollectionPath(): string {
    return "/organisations";
  }

  public getOrganisationDocumentPath(organisationId: string): string {
    return `${this.getOrganisationsCollectionPath()}/${organisationId}`;
  }

  public getCataloguesCollectionPath(organisationId: string): string {
    return `${this.getOrganisationDocumentPath(organisationId)}/catalogues`;
  }

  public getCatalogueDocumentPath(organisationId: string, catalogueId: string): string {
    return `${this.getCataloguesCollectionPath(organisationId)}/${catalogueId}`;
  }

  public getCatalogueCategoriesCollectionPath(organisationId: string, catalogueId: string): string {
    return `${this.getCatalogueDocumentPath(organisationId, catalogueId)}/categories`;
  }

  public getCatalogueCategoryDocumentPath(organisationId: string, catalogueId: string, categoryId: string): string {
    return `${this.getCatalogueCategoriesCollectionPath(organisationId, catalogueId)}/${categoryId}`;
  }

  public getCatalogueRequirementsCollectionPath(
    organisationId: string,
    catalogueId: string,
    categoryId: string
  ): string {
    return `${this.getCatalogueCategoryDocumentPath(organisationId, catalogueId, categoryId)}/requirements`;
  }

  public getCatalogueRequirementDocumentPath(
    organisationId: string,
    catalogueId: string,
    categoryId: string,
    requirementId: string
  ): string {
    return `${this.getCatalogueRequirementsCollectionPath(organisationId, catalogueId, categoryId)}/${requirementId}`;
  }

  public getClientsCollectionPath(organisationId: string): string {
    return `${this.getOrganisationDocumentPath(organisationId)}/clients`;
  }

  public getClientDocumentPath(organisationId: string, clientId: string): string {
    return `${this.getClientsCollectionPath(organisationId)}/${clientId}`;
  }

  public getShoeboxItemsCollectionPath(organisationId: string, clientId: string): string {
    return `${this.getClientDocumentPath(organisationId, clientId)}/shoeboxItems`;
  }

  public getShoeboxItemDocumentPath(organisationId: string, clientId: string, shoeboxId: string): string {
    return `${this.getShoeboxItemsCollectionPath(organisationId, clientId)}/${shoeboxId}`;
  }

  public getPeriodsCollectionPath(organisationId: string, clientId: string): string {
    return `${this.getClientDocumentPath(organisationId, clientId)}/periods`;
  }

  public getPeriodDocumentPath(organisationId: string, clientId: string, periodId: string): string {
    return `${this.getPeriodsCollectionPath(organisationId, clientId)}/${periodId}`;
  }

  public getFilesCollectionPath(organisationId: string, clientId: string, periodId: string): string {
    return `${this.getPeriodDocumentPath(organisationId, clientId, periodId)}/files`;
  }

  public getFileDocumentPath(organisationId: string, clientId: string, periodId: string, fileId: string): string {
    return `${this.getFilesCollectionPath(organisationId, clientId, periodId)}/${fileId}`;
  }

  public getEntitiesCollectionPath(organisationId: string, clientId: string, periodId: string): string {
    return `${this.getPeriodDocumentPath(organisationId, clientId, periodId)}/entities`;
  }

  public getEntityDocumentPath(organisationId: string, clientId: string, periodId: string, entityId: string): string {
    return `${this.getEntitiesCollectionPath(organisationId, clientId, periodId)}/${entityId}`;
  }

  public getCategoriesCollectionPath(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId?: string
  ): string {
    return categoryId
      ? `${this.getEntityDocumentPath(
          organisationId,
          clientId,
          periodId,
          entityId
        )}/categories/${categoryId}/categories`
      : `${this.getEntityDocumentPath(organisationId, clientId, periodId, entityId)}/categories`;
  }

  public getCategoryDocumentPath(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId?: string
  ): string {
    return subCategoryId
      ? `${this.getCategoriesCollectionPath(organisationId, clientId, periodId, entityId, categoryId)}/${subCategoryId}`
      : `${this.getCategoriesCollectionPath(organisationId, clientId, periodId, entityId)}/${categoryId}`;
  }

  public getRequirementsCollectionPath(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId?: string
  ): string {
    return `${this.getCategoryDocumentPath(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId
    )}/requirements`;
  }

  public getRequirementDocumentPath(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId: string | undefined,
    requirementId: string
  ): string {
    return `${this.getRequirementsCollectionPath(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId
    )}/${requirementId}`;
  }

  public getSystemInfoDocumentPath() {
    return "/system/info";
  }

  public getDocumentPath(
    organisationId: string,
    clientId?: string,
    periodId?: string,
    entityId?: string,
    categoryId?: string,
    subCategoryId?: string,
    requirementId?: string
  ): string {
    let path = `/organisations/${organisationId}`;

    if (clientId) {
      path += `/clients/${clientId}`;

      if (periodId) {
        path += `/periods/${periodId}`;

        if (entityId) {
          path += `/entities/${entityId}`;

          if (categoryId) {
            path += `/categories/${categoryId}`;

            if (subCategoryId) {
              path += `/categories/${subCategoryId}`;
            }

            if (requirementId) {
              path += `/requirements/${requirementId}`;
            }
          }
        }
      }
    }

    return path;
  }
}

const usePathService = () => {
  return useMemo(() => {
    return new PathService();
  }, []);
};

export default usePathService;
