import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import { RequirementType } from "../../../types";
import SubmitButton from "../../button/SubmitButton";
import useRequirementService from "../../../hooks/RequirementService";

const formSchema = yup
  .object({
    requirementName: yup.string().trim().required("Requirement name is required"),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

type EditRequirementDialogProps = {
  organisationId: string;
  clientId: string;
  periodId: string;
  entityId: string;
  categoryId: string;
  subCategoryId?: string;
  requirement: RequirementType;
  open: boolean;
  onClose: (requirementEdited: boolean) => void;
};

const EditRequirementDialog = ({
  organisationId,
  clientId,
  periodId,
  entityId,
  categoryId,
  subCategoryId,
  requirement,
  open,
  onClose,
}: EditRequirementDialogProps) => {
  const requirementService = useRequirementService();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    getValues,
  } = useForm<FormType>({ resolver: yupResolver(formSchema) });

  const onSubmit = async (data: FormType) => {
    const { requirementName } = data;
    await requirementService.editRequirement(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      requirement.requirementId,
      requirementName
    );
    handleClose(true);
  };

  const handleClose = (requirementEdited: boolean) => {
    reset({
      requirementName: requirementEdited ? getValues("requirementName") : requirement.name,
    });
    onClose(requirementEdited);
  };

  useEffect(() => {
    setValue("requirementName", requirement.name);
  }, [requirement.name, setValue]);

  return (
    <Dialog open={open} onClose={() => handleClose(false)} disableRestoreFocus fullWidth maxWidth="md">
      <DialogTitle>Edit requirement</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            label="Requirement name"
            error={!!errors.requirementName}
            helperText={errors.requirementName?.message}
            {...register("requirementName")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} variant="outlined" color="secondary">
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Save" />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditRequirementDialog;
