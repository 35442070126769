import { useMemo } from "react";

export class LocalStorageService {
  public getSelectedPeriodId(clientId: string): string | null {
    const key = this.getSelectedPeriodIdKey(clientId);
    return localStorage.getItem(key);
  }

  public setSelectedPeriodId(clientId: string, periodId: string): void {
    const key = this.getSelectedPeriodIdKey(clientId);
    localStorage.setItem(key, periodId);
  }

  private getSelectedPeriodIdKey(clientId: string): string {
    return `periodId-${clientId}`;
  }
}

const useLocalStorageService = () => {
  return useMemo(() => new LocalStorageService(), []);
};

export default useLocalStorageService;
