import React, { ReactNode } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { TableCell, TableRow } from "@mui/material";
import { TableRowProps } from "@mui/material/TableRow/TableRow";
import Box from "@mui/material/Box";
import TaxyTooltip from "../components/TaxyTooltip";
import { DragType } from "../types";

type SortableTableRowProps = TableRowProps & {
  id: string;
  children: ReactNode;
  enabled?: boolean;
};

const SortableTableRow = ({ id, enabled = true, children, ...tableRowProps }: SortableTableRowProps) => {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    data: {
      type: DragType.SORTABLE,
    },
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <TableRow
      ref={setNodeRef}
      {...attributes}
      style={style}
      sx={{
        opacity: isDragging ? 0.5 : 1,
      }}
      {...tableRowProps}
    >
      <TableCell sx={{ p: 0 }}>
        {enabled && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TaxyTooltip title="Hold to drag and re-order">
              <DragIndicatorIcon {...listeners} sx={{ cursor: "pointer", mr: 0.5, color: "grey.500" }} />
            </TaxyTooltip>
          </Box>
        )}
      </TableCell>
      {children}
    </TableRow>
  );
};

export default SortableTableRow;
