import React from "react";
import { Chip } from "@mui/material";
import { RequirementType } from "../../types";
import { useApplicationState } from "../../hooks/ApplicationState";
import {
  RequirementStatusEnumAgentDisplay,
  RequirementStatusEnumClientDisplay,
  RequirementStatusEnumColour,
} from "../../enums/RequirementStatus";

type RequirementsStatusProps = {
  requirement?: RequirementType;
};

const RequirementStatusChip = ({ requirement }: RequirementsStatusProps) => {
  const { isAccountant } = useApplicationState();

  if (!requirement) {
    return null;
  }

  return (
    <Chip
      size="small"
      sx={{ p: 1, fontWeight: "500" }}
      label={
        isAccountant
          ? RequirementStatusEnumAgentDisplay[requirement.status]
          : RequirementStatusEnumClientDisplay[requirement.status]
      }
      variant="outlined"
      color={RequirementStatusEnumColour[requirement.status]}
      className="requirementStatus"
    />
  );
};

export default RequirementStatusChip;
