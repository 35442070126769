import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import TreeView from "@mui/lab/TreeView";
import { Box, IconButton } from "@mui/material";
import CategoryIcon from "@mui/icons-material/Folder";
import RequirementIcon from "@mui/icons-material/ListAlt";
import { Delete, Edit, PostAdd } from "@mui/icons-material";
import "./CategoryTree.css";
import {
  CatalogueType,
  CategoryCatalogueType,
  EntityType,
  RequirementCatalogueType,
  RequirementType as RequirementOrigType,
} from "../types";
import { MinusSquare, PlusSquare } from "./StyledTreeItem";
import RequirementTreeItem from "./RequirementTreeItem";
import CategoryTreeItem from "./CategoryTreeItem";
import TaxyTooltip from "./TaxyTooltip";

type RequirementType = RequirementOrigType | RequirementCatalogueType;

interface CategoryTreeProps {
  clientId?: string;
  periodId?: string;
  entity?: EntityType;
  parent: CategoryCatalogueType | CatalogueType;
  categories: Record<string, CategoryCatalogueType>;
  categorySelect: (category: CategoryCatalogueType) => void;
  selectedCategoryId?: string;
  requirementSelect: (requirement: RequirementType) => void;
  selectedRequirementId?: string;
  editCategory: (category: CategoryCatalogueType) => void;
  editRequirement: (category: CategoryCatalogueType, requirement: RequirementType) => void;
  isAllExpanded?: { expanded: boolean };
  addRequirement: (parentCategory: CategoryCatalogueType) => void;
  removeCategory: (category: CategoryCatalogueType) => void;
  removeRequirement: (category: CategoryCatalogueType, requirement: RequirementType) => void;
  updateNoteForRequirement?: (
    category: CategoryCatalogueType,
    requirement: RequirementType,
    note: string,
    isChange: boolean
  ) => void;
  assignUserToRequirement?: (category: CategoryCatalogueType, requirement: RequirementType, uid: string) => void;
  orderRequirement: (
    category: CategoryCatalogueType,
    sourceRequirement: RequirementType,
    targetRequirement: RequirementType,
    moveBefore?: boolean,
    moveAfter?: boolean
  ) => void;
  moveRequirement: (
    sourceEntity: EntityType | undefined,
    targetEntity: EntityType | undefined,
    sourceCategory: CategoryCatalogueType,
    sourceRequirement: RequirementType,
    targetCategory: CategoryCatalogueType,
    targetRequirement?: RequirementType,
    moveAfter?: boolean
  ) => void;
  orderCategory: (
    sourceCategory: CategoryCatalogueType,
    targetCategory: CategoryCatalogueType,
    moveBefore?: boolean,
    moveAfter?: boolean
  ) => void;
}

const CategoryTree: React.FC<CategoryTreeProps> = (props) => {
  const {
    categories,
    categorySelect,
    selectedCategoryId,
    requirementSelect,
    selectedRequirementId,
    editCategory,
    editRequirement,
    addRequirement,
    removeCategory,
    isAllExpanded,
    removeRequirement,
    orderRequirement,
    moveRequirement,
    parent,
    orderCategory,
    entity,
  } = props;
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  useEffect(() => {
    if (isAllExpanded?.expanded) {
      setExpandedNodes((expandedNodes) => {
        // keep any expanded requirements when toggling expand all
        const expandedRequirements = Object.values(categories)
          .map((category) => Object.keys(category.requirements || {}))
          .reduce((arr, requirementIds) => [...arr, ...requirementIds], []);
        const newExpandedNodes = expandedNodes.filter((nodeId) => expandedRequirements.includes(nodeId));
        Object.entries(categories || {}).forEach(([categoryId, category]) => {
          newExpandedNodes.push(categoryId);
        });
        return newExpandedNodes;
      });
    } else if (isAllExpanded?.expanded === false) {
      setExpandedNodes([]);
    }
  }, [isAllExpanded, categories]);

  useEffect(() => {
    setExpandedNodes((expandedNodes) => {
      const newExpandedNodes: string[] = [...expandedNodes];
      if (newExpandedNodes.length > expandedNodes.length) {
        return newExpandedNodes;
      }
      return expandedNodes;
    });
  }, [categories]);

  const requirementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (requirementRef.current) {
      requirementRef.current.scrollIntoView({ block: "start" });
    }
  }, [requirementRef]);

  const fieldRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (fieldRef.current) {
      fieldRef.current.scrollIntoView();
    }
  }, [fieldRef]);

  const requirementFieldRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (requirementFieldRef.current) {
      //	Wait for the tree animations
      setTimeout(() => requirementFieldRef?.current?.scrollIntoView(), 1000);
    }
  }, [requirementFieldRef]);

  return !categories ? null : (
    <TreeView
      aria-label="customized"
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      expanded={expandedNodes}
      onNodeSelect={(e: SyntheticEvent<Element, Event>, selectNode: string) => {
        e.preventDefault();
        e.stopPropagation();
        const category = categories[selectNode];
        if (category) {
          categorySelect(category);
        } else {
          //	Grab all the requirement in this whole tree
          const allRequirements = Object.entries(categories)
            .map(([, category]) => {
              if (category.requirements) {
                return Object.entries(category.requirements).map(([, requirement]) => requirement);
              }
              return [];
            })
            .flat();

          const myRequirement = allRequirements.find((requirement) => requirement?.requirementId === selectNode);

          if (myRequirement) {
            requirementSelect(myRequirement);
          }
        }
      }}
      onNodeToggle={(e: SyntheticEvent<Element, Event>, toggledArray: string[]) => {
        setExpandedNodes(toggledArray);
      }}
      sx={{ minHeight: 24, flexGrow: 1, minWidth: 180, overflowY: "auto" }}
    >
      {Object.entries(categories)
        .sort(([, a], [, b]) =>
          a.order !== undefined && b.order !== undefined
            ? a.order === b.order
              ? 0
              : a.order < b.order
              ? -1
              : 1
            : a.createdTimestamp === b.createdTimestamp
            ? 0
            : a.createdTimestamp < b.createdTimestamp
            ? -1
            : 1
        )
        .map(([categoryId, category], i) => {
          return (
            <CategoryTreeItem
              itemIndex={i}
              entity={entity}
              parent={parent}
              category={category}
              orderCategory={orderCategory}
              moveRequirement={moveRequirement}
              lastItem={i === Object.keys(categories || {}).length - 1}
              key={categoryId}
              nodeId={categoryId}
              label={
                <>
                  <Box
                    component="span"
                    onClick={(e: { stopPropagation: () => void }) => {
                      e.stopPropagation();
                      // TODO: typescript bug, still present @4.9.5
                      // if (categories[categoryId]) {
                      // 	categorySelect(categories[categoryId])
                      // }
                      const category = categories[categoryId];
                      if (category) {
                        categorySelect(category);
                      }
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CategoryIcon fontSize="small" sx={{ marginRight: 1 }} />
                    {category.name}
                  </Box>
                  {selectedCategoryId === categoryId && (
                    <Box component="span" sx={{ display: "flex", alignItems: "flex-start" }}>
                      <TaxyTooltip title="Edit category name">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            editCategory(category);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </TaxyTooltip>

                      <TaxyTooltip title="Delete category">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            removeCategory(category);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TaxyTooltip>

                      <TaxyTooltip title="Add requirement">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            addRequirement(category);
                          }}
                        >
                          <PostAdd />
                        </IconButton>
                      </TaxyTooltip>
                    </Box>
                  )}
                </>
              }
            >
              {category.requirements &&
                Object.keys(category.requirements).length > 0 &&
                Object.entries(category.requirements)
                  .sort(([, a], [, b]) =>
                    a.order !== undefined && b.order !== undefined
                      ? a.order === b.order
                        ? 0
                        : a.order < b.order
                        ? -1
                        : 1
                      : a.createdTimestamp === b.createdTimestamp
                      ? 0
                      : a.createdTimestamp < b.createdTimestamp
                      ? -1
                      : 1
                  )
                  .map(([requirementId, requirement], i) => (
                    <RequirementTreeItem
                      itemIndex={i}
                      entity={entity}
                      category={category}
                      requirement={requirement}
                      orderRequirement={orderRequirement}
                      moveRequirement={moveRequirement}
                      lastItem={i === Object.keys(category.requirements || {}).length - 1}
                      key={requirementId}
                      nodeId={requirementId}
                      label={
                        <>
                          <Box
                            component="span"
                            onClick={(e: { stopPropagation: () => void }) => {
                              e.stopPropagation();
                              requirementSelect(requirement);
                            }}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              maxWidth: "90%",
                            }}
                          >
                            <RequirementIcon
                              fontSize="small"
                              sx={{
                                marginRight: 1,
                              }}
                            />
                            {requirement.name}
                          </Box>
                          {selectedRequirementId === requirementId && (
                            <Box component="span">
                              <TaxyTooltip title="Edit requirement name">
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    editRequirement(category, requirement);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </TaxyTooltip>
                              <TaxyTooltip title="Delete requirement">
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeRequirement(category, requirement);
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              </TaxyTooltip>
                            </Box>
                          )}
                        </>
                      }
                    ></RequirementTreeItem>
                  ))}
              {category.categories && Object.keys(category.categories).length > 0 && (
                <CategoryTree
                  {...props}
                  parent={category}
                  categories={category.categories}
                  categorySelect={categorySelect}
                />
              )}
            </CategoryTreeItem>
          );
        })}
    </TreeView>
  );
};

export default CategoryTree;
