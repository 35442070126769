import { RequirementCountsType, RequirementType } from "../../types";
import { some } from "lodash";
import CircleIcon from "@mui/icons-material/Circle";
import React, { useEffect, useState } from "react";
import { useApplicationState } from "../../hooks/ApplicationState";

type StatusDotProps = {
  requirement?: RequirementType;
  requirements?: RequirementType[];
  requirementCounts?: RequirementCountsType;
};

const StatusDot = ({ requirement, requirements, requirementCounts }: StatusDotProps) => {
  const { isAccountant } = useApplicationState();
  const [showDot, setShowDot] = useState<boolean>();

  useEffect(() => {
    // when a single requirement is given
    if (requirement !== undefined) {
      setShowDot(isAccountant ? requirement.status === "WITH_ACCOUNTANT" : requirement.status === "WITH_CLIENT");
    }

    // when a list of requirements is given
    else if (requirements !== undefined) {
      setShowDot(
        isAccountant
          ? some(requirements, (requirement) => requirement.status === "WITH_ACCOUNTANT")
          : some(requirements, (requirement) => requirement.status === "WITH_CLIENT")
      );
    }

    // when requirement counts are given
    else if (requirementCounts) {
      setShowDot(isAccountant ? requirementCounts.WITH_ACCOUNTANT > 0 : requirementCounts.WITH_CLIENT > 0);
    }
  }, [isAccountant, requirement, requirements, requirementCounts]);

  return <CircleIcon sx={{ color: "statusReview.main", p: 0.5, opacity: showDot ? 1 : 0 }} fontSize="small" />;
};

export default StatusDot;
