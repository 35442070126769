import React, { useCallback, useEffect, useState } from "react";
import { useApplicationState } from "../../hooks/ApplicationState";
import useClientService from "../../hooks/ClientService";
import {
  CategoryType,
  ClientType,
  EntityType,
  OrganisationType,
  PeriodType,
  RequirementType,
  UserType,
} from "../../types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { find, get, isEmpty, map, orderBy, size } from "lodash";
import TableContainer from "@mui/material/TableContainer";
import KeyboardOutlinedIcon from "@mui/icons-material/KeyboardOutlined";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import RequirementDetailsDialog from "../dialogs/RequirementDetailsDialog";
import RequirementStatusChip from "../client/RequirementStatusChip";
import FormattedTimestamp from "../FormattedTimestamp";
import { EntityEnum } from "../../enums";
import { ReactComponent as IndividualIcon } from "../images/IndividualIcon.svg";
import { ReactComponent as CompanyIcon } from "../images/CompanyIcon.svg";
import { ReactComponent as TrustIcon } from "../images/TrustIcon.svg";
import Box from "@mui/material/Box";
import ItemCount from "../client/ItemCount";
import MessagesIcon from "../images/MessagesIcon.svg";
import FilesIcon from "../images/FilesIcon.svg";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useHotkeys } from "react-hotkeys-hook";
import IconButton from "@mui/material/IconButton";
import WorkListKeyboardShortcutsDialog from "./WorkListKeyboardShortcutsDialog";
import useOrganisationService from "../../hooks/OrganisationService";
import useUserService from "../../hooks/UserService";
import FormattedUserName from "../FormattedUserName";
import WorkListSearch from "./WorkListSearch";
import TaxyTooltip from "../TaxyTooltip";

const ICONS = {
  [EntityEnum.Individual]: <IndividualIcon />,
  [EntityEnum.Company]: <CompanyIcon />,
  [EntityEnum.Trust]: <TrustIcon />,
};

type WorkListItemType = {
  organisation: OrganisationType;
  client: ClientType;
  period: PeriodType;
  manager?: UserType;
  entity: EntityType;
  category: CategoryType;
  subCategory?: CategoryType;
  requirement: RequirementType;
};

type SortDirection = "asc" | "desc";
type SortBy = "organisation" | "manager" | "client" | "period" | "entity" | "category" | "lastModified";

const sortFunctions: Record<SortBy, (item: WorkListItemType) => any> = {
  organisation: ({ organisation }: WorkListItemType) => get(organisation, "name"),
  manager: ({ manager }: WorkListItemType) => (manager ? `${manager.firstName} ${manager.lastName}` : undefined),
  client: ({ client }: WorkListItemType) => get(client, "name"),
  period: ({ client }: WorkListItemType) => get(client, "activePeriodName"),
  entity: ({ entity }: WorkListItemType) => get(entity, "name"),
  category: ({ category }: WorkListItemType) => get(category, "name"),
  lastModified: ({ requirement }: WorkListItemType) => get(requirement, "modifiedTimestamp"),
};

const WorkList = () => {
  const { organisationIds, isAccountant } = useApplicationState();
  const organisationService = useOrganisationService();
  const clientService = useClientService();
  const userService = useUserService();

  const [organisations, setOrganisations] = useState<OrganisationType[]>([]);
  const [accountants, setAccountants] = useState<UserType[]>([]);
  const [clients, setClients] = useState<ClientType[]>([]);

  // used to force a reload of the worklist
  const [reloadCount, setReloadCount] = useState<number>(0);

  const [workListItems, setWorkListItems] = useState<WorkListItemType[]>([]);
  const [selectedItem, setSelectedItem] = useState<WorkListItemType>();

  const [workListItemDetailsDialogOpen, setWorkListItemDetailsDialogOpen] = useState<boolean>(false);
  const [keyboardShortcutsDialogOpen, setKeyboardShortcutsDialogOpen] = useState<boolean>(false);

  const [sortBy, setSortBy] = useState<SortBy>("client");
  const [sortDirection, setSortDirection] = useState<SortDirection>("asc");

  const [highlightedRowIndex, setHighlightedRowIndex] = useState<number>(0);

  useHotkeys("down", () => {
    if (!keyboardShortcutsDialogOpen && highlightedRowIndex + 1 < size(workListItems)) {
      setHighlightedRowIndex((prevState) => prevState + 1);
    }
  });

  useHotkeys("up", () => {
    if (!keyboardShortcutsDialogOpen && highlightedRowIndex > 0) {
      setHighlightedRowIndex((prevState) => prevState - 1);
    }
  });

  useHotkeys("enter", () => {
    if (!keyboardShortcutsDialogOpen) {
      const item = workListItems[highlightedRowIndex];
      if (item) {
        onItemSelected(item);
      }
    }
  });

  const loadOrganisations = useCallback(async () => {
    const results = await Promise.all(
      map(organisationIds, (organisationId) => organisationService.get(organisationId))
    );
    setOrganisations(results);
  }, [organisationIds, organisationService]);

  useEffect(() => {
    void loadOrganisations();
  }, [loadOrganisations]);

  const loadAccountants = useCallback(async () => {
    const results = await userService.listAccountantUsers(organisationIds);
    setAccountants(results);
  }, [organisationIds, userService]);

  useEffect(() => {
    isAccountant && void loadAccountants();
  }, [loadAccountants, isAccountant]);

  const loadClients = useCallback(async () => {
    const results = await clientService.getClients(organisationIds);
    setClients(results);
  }, [clientService, organisationIds]);

  useEffect(() => {
    void loadClients();
  }, [loadClients]);

  useEffect(() => {
    // ensure the highlighted row is still in range
    if (isEmpty(workListItems)) {
      setHighlightedRowIndex(0);
    } else {
      const maxRowIndex = workListItems.length - 1;
      if (highlightedRowIndex > maxRowIndex) {
        setHighlightedRowIndex(maxRowIndex);
      }
    }
  }, [workListItems, highlightedRowIndex]);

  useEffect(() => {
    if (workListItemDetailsDialogOpen) {
      const item = workListItems[highlightedRowIndex];
      if (item) {
        onItemSelected(item);
      }
    }
  }, [workListItems, workListItemDetailsDialogOpen, highlightedRowIndex]);

  // if the worklist is updated refresh the selected item to pick up any changes
  useEffect(() => {
    if (selectedItem) {
      const updatedItem = find(
        workListItems,
        (item) => item.requirement.requirementId === selectedItem.requirement.requirementId
      );
      setSelectedItem(updatedItem);
    }
  }, [workListItems, selectedItem]);

  const reload = () => {
    setReloadCount((previousReloadCount) => previousReloadCount + 1);
  };

  const onItemSelected = (worklistItem: WorkListItemType) => {
    setSelectedItem(worklistItem);
    setWorkListItemDetailsDialogOpen(true);
  };

  const onWorkListItemDetailsClose = (itemUpdated: boolean) => {
    setSelectedItem(undefined);
    setWorkListItemDetailsDialogOpen(false);
    if (itemUpdated) {
      reload();
    }
  };

  const showKeyboardShortcutsDialog = () => {
    setKeyboardShortcutsDialogOpen(true);
  };

  const sortTable = (fieldName: SortBy) => {
    setSortBy(fieldName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const SortLabel = ({ field, label }: { field: SortBy; label: string }) => {
    return (
      <TableSortLabel active={sortBy === field} direction={sortDirection} onClick={() => sortTable(field)}>
        {label}
      </TableSortLabel>
    );
  };

  const getWorkListItems = useCallback(() => {
    return orderBy(workListItems, sortFunctions[sortBy], sortDirection);
  }, [workListItems, sortBy, sortDirection]);

  return (
    <>
      <Box sx={{ borderBottom: "1px solid", borderColor: "neutral.light", p: 2 }}>
        <Typography variant="h4">Worklist</Typography>
      </Box>

      <Grid container spacing={1} sx={{ p: 2 }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
            <Typography variant="body2" color="text.secondary">
              Note: only requirements from the "active period" are shown.
            </Typography>
          </Box>

          <TaxyTooltip title="View keyboard shortcuts">
            <IconButton onClick={showKeyboardShortcutsDialog} sx={{ minWidth: "inherit" }}>
              <KeyboardOutlinedIcon />
            </IconButton>
          </TaxyTooltip>
        </Grid>

        <Grid item xs={12}>
          <WorkListSearch
            organisations={organisations}
            clients={clients}
            accountants={accountants}
            reloadCount={reloadCount}
            onSearchResults={setWorkListItems}
          />
        </Grid>

        <Grid item xs={12} sx={{ mb: 4 }}>
          <TableContainer component={Paper} square elevation={0}>
            <Table aria-label="Requirements table">
              <TableHead>
                <TableRow>
                  {isAccountant && (
                    <TableCell>
                      <SortLabel field="organisation" label="Team" />
                    </TableCell>
                  )}
                  <TableCell>
                    <SortLabel field="client" label="Client" />
                  </TableCell>
                  <TableCell>
                    <SortLabel field="period" label="Period" />
                  </TableCell>
                  <TableCell>
                    <SortLabel field="entity" label="Entity" />
                  </TableCell>
                  {isAccountant && (
                    <TableCell>
                      <SortLabel field="manager" label="Manager" />
                    </TableCell>
                  )}
                  <TableCell>
                    <SortLabel field="category" label="Category" />
                  </TableCell>
                  <TableCell sx={{ width: "40%" }}>Requirement</TableCell>
                  <TableCell>Comments / Files</TableCell>
                  <TableCell>
                    <SortLabel field="lastModified" label="Last modified" />
                  </TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getWorkListItems().map((item, index) => (
                  <TableRow
                    key={item.requirement.requirementId}
                    hover
                    sx={{ cursor: "pointer" }}
                    onClick={() => onItemSelected(item)}
                    selected={index === highlightedRowIndex}
                  >
                    {isAccountant && <TableCell>{item.organisation.name}</TableCell>}
                    <TableCell>{item.client.name}</TableCell>
                    <TableCell>{item.client.activePeriodName}</TableCell>
                    <TableCell>
                      <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
                        {ICONS[item.entity.entityType]}{" "}
                        <Box component="span" sx={{ ml: 2 }}>
                          {" "}
                          {item.entity.name}
                        </Box>
                      </Box>
                    </TableCell>
                    {isAccountant && (
                      <TableCell>
                        <FormattedUserName user={item.manager} defaultValue="Not assigned" />
                      </TableCell>
                    )}
                    <TableCell>
                      {item.category.name} {item.subCategory && `/ ${item.subCategory.name}`}
                    </TableCell>
                    <TableCell>{item.requirement.name}</TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <ItemCount icon={MessagesIcon} alt="Comments" count={size(item.requirement.comments)} />
                        <ItemCount icon={FilesIcon} alt="Files" count={size(item.requirement.fileIds)} />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <FormattedTimestamp timestamp={item.requirement.modifiedTimestamp} />
                    </TableCell>
                    <TableCell>
                      <RequirementStatusChip requirement={item.requirement} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {selectedItem && (
          <RequirementDetailsDialog
            organisationId={selectedItem.organisation.organisationId}
            client={selectedItem.client}
            period={selectedItem.period}
            entity={selectedItem.entity}
            category={selectedItem.category}
            subCategory={selectedItem.subCategory}
            requirement={selectedItem.requirement}
            open={workListItemDetailsDialogOpen}
            onClose={onWorkListItemDetailsClose}
            onRequirementUpdated={reload}
          />
        )}

        <WorkListKeyboardShortcutsDialog
          open={keyboardShortcutsDialogOpen}
          onClose={() => setKeyboardShortcutsDialogOpen(false)}
        />
      </Grid>
    </>
  );
};

export default WorkList;
