import * as yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { CategoryType } from "../../../types";
import { yupResolver } from "@hookform/resolvers/yup";
import SubmitButton from "../../button/SubmitButton";
import Grid from "@mui/material/Grid";
import useRequirementService from "../../../hooks/RequirementService";

export const formSchema = yup
  .object({
    parentCategoryId: yup.string().required(),
    requirementName: yup.string().trim().required("Requirement name is required"),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

type AddRequirementDialogProps = {
  organisationId: string;
  clientId: string;
  periodId: string;
  entityId: string;
  category: CategoryType;
  open: boolean;
  onClose: (requirementAdded: boolean) => void;
};

const AddRequirementDialog = ({
  organisationId,
  clientId,
  periodId,
  entityId,
  category,
  open,
  onClose,
}: AddRequirementDialogProps) => {
  const requirementService = useRequirementService();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormType>({ resolver: yupResolver(formSchema) });

  const onSubmit = async (data: FormType) => {
    const { requirementName, parentCategoryId } = data;
    const categoryId = category.categoryId;
    const subCategoryId = parentCategoryId === categoryId ? undefined : parentCategoryId;
    await requirementService.createRequirement(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      requirementName
    );
    handleClose(true);
  };

  const handleClose = (requirementAdded: boolean) => {
    reset();
    onClose(requirementAdded);
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)} disableRestoreFocus fullWidth maxWidth="md">
      <DialogTitle>Add requirement</DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                label="Requirement name"
                error={!!errors.requirementName}
                helperText={errors.requirementName?.message}
                {...register("requirementName")}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Category"
                select
                fullWidth
                defaultValue={category.categoryId}
                error={!!errors.parentCategoryId}
                helperText={errors.parentCategoryId?.message}
                {...register("parentCategoryId")}
              >
                <MenuItem key={category.categoryId} value={category.categoryId}>
                  {category.name}
                </MenuItem>
                {category.categories.map((subCategory) => (
                  <MenuItem key={subCategory.categoryId} value={subCategory.categoryId}>
                    {subCategory.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleClose(false)} variant="outlined" color="secondary">
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Add" />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddRequirementDialog;
