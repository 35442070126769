import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useCatalogueService from "../../hooks/CatalogueService";
import SubmitButton from "../button/SubmitButton";
import { RequirementCatalogueType } from "../../types";
import { useEffect } from "react";

const formSchema = yup
  .object({
    requirementName: yup.string().trim().required("Requirement name is required"),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

type EditCatalogueRequirementDialogProps = {
  organisationId: string;
  catalogueId: string;
  categoryId: string;
  requirement: RequirementCatalogueType;
  open: boolean;
  onClose: (requirementUpdated: boolean) => void;
};

const EditCatalogueRequirementDialog = ({
  organisationId,
  catalogueId,
  categoryId,
  requirement,
  open,
  onClose,
}: EditCatalogueRequirementDialogProps) => {
  const catalogueService = useCatalogueService();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    getValues,
  } = useForm<FormType>({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (data: FormType) => {
    const { requirementName } = data;
    await catalogueService.editRequirementName(
      organisationId,
      catalogueId,
      categoryId,
      requirement.requirementId,
      requirementName
    );
    handleClose(true);
  };

  const handleClose = (requirementUpdated: boolean) => {
    reset({
      requirementName: requirementUpdated ? getValues("requirementName") : requirement.name,
    });
    onClose(requirementUpdated);
  };

  useEffect(() => {
    setValue("requirementName", requirement.name);
  }, [requirement.name, setValue]);

  return (
    <Dialog open={open} onClose={() => handleClose(false)} disableRestoreFocus fullWidth maxWidth="md">
      <DialogTitle>Edit requirement</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            label="Requirement name"
            error={!!errors.requirementName}
            helperText={errors.requirementName?.message}
            {...register("requirementName")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} variant="outlined" color="secondary">
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Save" />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditCatalogueRequirementDialog;
