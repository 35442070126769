import { connectAuthEmulator, getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { connectFirestoreEmulator, doc, getDoc, getFirestore } from "firebase/firestore";
import { FirebaseApp, getApp, initializeApp } from "firebase/app";
import { useCallback, useEffect, useState } from "react";
import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { UserType } from "../types";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import firebaseConfig from "../config/firebase";
import { includes, isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

const loadingCountState = atom<number>({
  key: "loadingCount",
  default: 0,
});

const isLoadingState = selector<boolean>({
  key: "isLoading",
  get: ({ get }) => {
    return get(loadingCountState) > 0;
  },
});

const userIdState = atom<string | undefined>({
  key: "userId",
  default: undefined,
});

// derived from userIdState
const userState = selector<UserType | undefined>({
  key: "user",
  get: async ({ get }) => {
    const userId = get(userIdState);
    if (userId) {
      try {
        const snapshot = await getDoc(doc(getFirestore(), `users/${userId}`));
        if (snapshot.exists()) {
          return snapshot.data() as UserType;
        }
      } catch (e: any) {
        // ignore, just return undefined
      }
    }
    return undefined;
  },
});

// derived from userState
const isAccountantState = selector<boolean>({
  key: "isAccountant",
  get: ({ get }) => {
    const user = get(userState);
    return includes(user?.roles, "ACCOUNTANT");
  },
});

// derived from userState
const isClientState = selector<boolean>({
  key: "isClient",
  get: ({ get }) => {
    const user = get(userState);
    return includes(user?.roles, "CLIENT");
  },
});

// derived from userState
const isOrganisationAdminState = selector<boolean>({
  key: "isOrganisationAdmin",
  get: ({ get }) => {
    const user = get(userState);
    return !isEmpty(user?.adminOrganisationIds);
  },
});

// derived from userState
const organisationIdsState = selector<string[]>({
  key: "organisationIds",
  get: ({ get }) => {
    const user = get(userState);
    return user?.organisationIds || [];
  },
});

// derived from userState
const adminOrganisationIdsState = selector<string[]>({
  key: "adminOrganisationIds",
  get: ({ get }) => {
    const user = get(userState);
    return user?.adminOrganisationIds || [];
  },
});

// derived from userState
const clientIdsState = selector<string[]>({
  key: "clientIds",
  get: ({ get }) => {
    const user = get(userState);
    return user?.clientIds || [];
  },
});

// derived from userState
const loggedInState = selector<boolean>({
  key: "loggedIn",
  get: ({ get }) => {
    const user = get(userState);
    return user !== undefined;
  },
});

const systemMessageState = atom<string | undefined>({
  key: "systemMessage",
  default: undefined,
});

const showRefreshMessageState = atom<boolean>({
  key: "showRefreshMessage",
  default: false,
});

type ApplicationState = {
  isLoading: boolean;
  incrementLoadingCount: () => void;
  decrementLoadingCount: () => void;
  userId?: string;
  user?: UserType;
  isAccountant: boolean;
  isClient: boolean;
  isOrganisationAdmin: boolean;
  organisationIds: string[];
  adminOrganisationIds: string[];
  clientIds: string[];
  loggedIn: boolean;
  isDev: boolean;
  app?: FirebaseApp;
  systemMessage?: string;
  setSystemMessage: (systemMessage?: string) => void;
  setShowRefreshMessage: (value: boolean) => void;
  showRefreshMessage: boolean;
  refreshIdToken: () => Promise<void>;
};

export const useApplicationState = (): ApplicationState => {
  const isLoading = useRecoilValue(isLoadingState);
  const setLoadingCount = useSetRecoilState(loadingCountState);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [app, setApp] = useState<FirebaseApp>();
  const [userId, setUserId] = useRecoilState(userIdState);
  const user = useRecoilValue(userState);
  const isAccountant = useRecoilValue(isAccountantState);
  const isClient = useRecoilValue(isClientState);
  const isOrganisationAdmin = useRecoilValue(isOrganisationAdminState);
  const organisationIds = useRecoilValue(organisationIdsState);
  const adminOrganisationIds = useRecoilValue(adminOrganisationIdsState);
  const clientIds = useRecoilValue(clientIdsState);
  const loggedIn = useRecoilValue(loggedInState);
  const [systemMessage, setSystemMessage] = useRecoilState(systemMessageState);
  const [showRefreshMessage, setShowRefreshMessage] = useRecoilState(showRefreshMessageState);

  const incrementLoadingCount = useCallback(() => {
    setLoadingCount((existing) => existing + 1);
  }, [setLoadingCount]);

  const decrementLoadingCount = useCallback(() => {
    setLoadingCount((existing) => existing - 1);
  }, [setLoadingCount]);

  const setSessionCookie = (token: string) => {
    global.document.cookie = `__session=${token};path=/`;
  };

  const clearSessionCookie = () => {
    global.document.cookie = "__session=;path=/";
  };

  const refreshIdToken = async () => {
    const { currentUser } = getAuth();
    if (currentUser) {
      const token = await currentUser.getIdToken();
      setSessionCookie(token);
    }
  };

  const initialise = useCallback(async () => {
    try {
      getApp();
    } catch (e) {
      console.debug("Initialising Firebase...");

      const isHostingEmulator = includes(["http://127.0.0.1:5000", "http://localhost:5000"], window.location.origin);

      if (process.env.NODE_ENV === "development" || isHostingEmulator) {
        console.debug(`Running locally, initialising Firebase with config: ${JSON.stringify(firebaseConfig, null, 2)}`);
        const firebaseApp = initializeApp(firebaseConfig);
        setApp(firebaseApp);
      } else {
        // https://firebase.google.com/docs/hosting/reserved-urls
        const config = await (await fetch("/__/firebase/init.json")).json();
        console.debug(`Running in Firebase, initialising Firebase with config: ${JSON.stringify(config, null, 2)}`);
        const firebaseApp = initializeApp(config);
        setApp(firebaseApp);
      }

      if (process.env.REACT_APP_TAXY_USE_EMULATORS || isHostingEmulator) {
        console.debug("Configuring Firebase emulators...");

        const auth = getAuth();
        connectAuthEmulator(auth, "http://127.0.0.1:9099");

        const functions = getFunctions(getApp(), "australia-southeast1");
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);

        const firestore = getFirestore();
        connectFirestoreEmulator(firestore, "127.0.0.1", 8080);

        const storage = getStorage();
        connectStorageEmulator(storage, "127.0.0.1", 9199);
      }
    }
  }, []);

  useEffect(() => {
    void initialise();
  }, [initialise]);

  useEffect(() => {
    if (app) {
      const auth = getAuth();

      // sign out when any of these pages are loaded
      if (includes(["/auth", "/reset-password", "/register"], pathname)) {
        void signOut(auth);
      }

      return onAuthStateChanged(auth, async (user) => {
        if (!includes(["/auth", "/reset-password", "/register", "/terms-and-conditions"], pathname)) {
          if (!user) {
            setUserId(undefined);
            clearSessionCookie();
          } else {
            const idToken = await user.getIdTokenResult();
            setUserId(idToken.claims.user_id as string);
            setSessionCookie(idToken.token);
          }
        }
      });
    }
  }, [app, pathname, setUserId, navigate]);

  return {
    app,
    isLoading,
    incrementLoadingCount,
    decrementLoadingCount,
    loggedIn,
    userId,
    user,
    isAccountant,
    isClient,
    isOrganisationAdmin,
    organisationIds,
    adminOrganisationIds,
    clientIds,
    isDev: process.env.NODE_ENV === "development",
    systemMessage,
    setSystemMessage,
    showRefreshMessage,
    setShowRefreshMessage,
    refreshIdToken,
  };
};
