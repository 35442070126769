import { useMemo } from "react";
import { CommentType, FileType, RequirementId, RequirementStatusType, RequirementType } from "../types";
import { v4 as uuidv4 } from "uuid";
import { concat, filter, findIndex } from "lodash";
import useFirestoreService, { FirestoreCreate, FirestoreService } from "./FirestoreService";
import { doc, DocumentReference, orderBy, query, serverTimestamp, UpdateData } from "firebase/firestore";

const getTimestampInSeconds = () => {
  return Math.floor(Date.now() / 1000);
};

export class RequirementService {
  constructor(readonly firestoreService: FirestoreService) {}

  public async getRequirement(id: RequirementId): Promise<RequirementType | undefined> {
    const { organisationId, clientId, periodId, entityId, categoryId, subCategoryId, requirementId } = id;
    const documentReference = this.firestoreService.getRequirementDocument(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      requirementId
    );
    const documentSnapshot = await this.firestoreService.getDocumentSnapshot(documentReference);
    return documentSnapshot.exists() ? this.firestoreService.toDocument<RequirementType>(documentSnapshot) : undefined;
  }

  public async createRequirement(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId: string | undefined,
    requirementName: string
  ): Promise<void> {
    const requirementsCollection = this.firestoreService.getRequirementsCollection(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId
    );

    const count = await this.firestoreService.countDocs(requirementsCollection);
    const requirementDocument = doc(requirementsCollection);
    const data: FirestoreCreate<RequirementType> = {
      requirementId: requirementDocument.id,
      clientId,
      periodId,
      createdTimestamp: serverTimestamp(),
      modifiedTimestamp: serverTimestamp(),
      name: requirementName,
      fileIds: [],
      status: "WITH_CLIENT",
      order: count,
    };
    await this.firestoreService.createDocument(requirementDocument, data);
  }

  public async editRequirement(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId: string | undefined,
    requirementId: string,
    requirementName: string
  ): Promise<void> {
    const requirementDocument = this.firestoreService.getRequirementDocument(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      requirementId
    );
    const data: UpdateData<RequirementType> = {
      name: requirementName,
    };

    await this.updateRequirement(requirementDocument, data);
  }

  public async deleteRequirement(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId: string | undefined,
    requirementId: string
  ): Promise<void> {
    const requirementsCollection = this.firestoreService.getRequirementsCollection(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId
    );
    const requirementsSnapshot = await this.firestoreService.getDocs(query(requirementsCollection, orderBy("order")));

    // delete the requirement with the given id, and update the order on the remaining requirements
    await this.firestoreService.inTransaction(async (transaction) => {
      let order = 0;
      for (const snapshot of requirementsSnapshot.docs) {
        if (snapshot.id === requirementId) {
          transaction.delete(snapshot.ref);
        } else {
          transaction.update(snapshot.ref, { order: order++ });
        }
      }
    });
  }

  public async setAccountantNote(id: RequirementId, note: string) {
    const { organisationId, clientId, periodId, entityId, categoryId, subCategoryId, requirementId } = id;
    const requirementDocument = this.firestoreService.getRequirementDocument(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      requirementId
    );
    const update: UpdateData<RequirementType> = {
      accountantNotes: note,
    };

    await this.updateRequirement(requirementDocument, update);
  }

  public async updateStatus(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId: string | undefined,
    requirementId: string,
    status: RequirementStatusType,
    commentText: string,
    userEmail: string | undefined
  ) {
    const requirementDocument = this.firestoreService.getRequirementDocument(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      requirementId
    );
    const documentSnapshot = await this.firestoreService.getDocumentSnapshot(requirementDocument);
    if (documentSnapshot.exists()) {
      const requirement = this.firestoreService.toDocument<RequirementType>(documentSnapshot);

      const firestoreUpdate: UpdateData<RequirementType> = {
        status,
      };

      if (commentText) {
        const { comments = [] } = requirement;

        firestoreUpdate.comments = [
          ...comments,
          {
            comment: commentText,
            createdBy: userEmail,
            createdAtTimestamp: getTimestampInSeconds(),
            id: uuidv4(),
            edited: false,
          },
        ];
      }

      await this.updateRequirement(requirementDocument, firestoreUpdate);
    }
  }

  public async addFile(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId: string | undefined,
    requirementId: string,
    file: FileType
  ) {
    const requirementDocument = this.firestoreService.getRequirementDocument(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      requirementId
    );
    await this.firestoreService.inTransaction(async (transaction) => {
      const documentSnapshot = await transaction.get(requirementDocument);
      if (documentSnapshot.exists()) {
        const requirement = this.firestoreService.toDocument<RequirementType>(documentSnapshot);
        const { fileId } = file;
        const fileIds = concat(requirement.fileIds, fileId);
        const update: UpdateData<RequirementType> = {
          fileIds,
          modifiedTimestamp: serverTimestamp(),
        };
        transaction.update(requirementDocument, update);
      }
    });
  }

  public async deleteComment(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId: string | undefined,
    requirementId: string,
    commentId: string
  ) {
    const requirementDocument = this.firestoreService.getRequirementDocument(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      requirementId
    );
    const documentSnapshot = await this.firestoreService.getDocumentSnapshot(requirementDocument);
    if (documentSnapshot.exists()) {
      const requirement = this.firestoreService.toDocument<RequirementType>(documentSnapshot);
      const comments = filter(requirement.comments, (comment) => comment.id !== commentId);
      const update: UpdateData<RequirementType> = {
        comments,
      };

      await this.updateRequirement(requirementDocument, update);
    }
  }

  public async updateComment(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId: string | undefined,
    requirementId: string,
    commentId: string,
    commentText: string
  ) {
    const requirementDocument = this.firestoreService.getRequirementDocument(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      requirementId
    );
    const documentSnapshot = await this.firestoreService.getDocumentSnapshot(requirementDocument);
    if (documentSnapshot.exists()) {
      const requirement = this.firestoreService.toDocument<RequirementType>(documentSnapshot);
      const { comments = [] } = requirement;

      const index = findIndex(comments, (comment: CommentType) => comment.id === commentId);
      if (index >= 0) {
        const existingComment = comments[index];
        if (existingComment) {
          comments[index] = {
            ...existingComment,
            comment: commentText,
            edited: true,
          };

          const update: UpdateData<RequirementType> = { comments };
          await this.updateRequirement(requirementDocument, update);
        }
      }
    }
  }

  public getRequirementPath(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId: string | undefined,
    requirementId: string
  ) {
    const requirementDocument = this.firestoreService.getRequirementDocument(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      requirementId
    );
    return requirementDocument.path;
  }

  private async updateRequirement(
    requirementDocument: DocumentReference,
    data: UpdateData<RequirementType>
  ): Promise<void> {
    return this.firestoreService.updateDocument(requirementDocument, {
      ...data,
      modifiedTimestamp: serverTimestamp(),
    });
  }
}

const useRequirementService = () => {
  const firestoreService = useFirestoreService();

  return useMemo(() => {
    return new RequirementService(firestoreService);
  }, [firestoreService]);
};

export default useRequirementService;
